import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import canvasConfetti from 'canvas-confetti'
import { Container } from '@mui/material';

const Spacer = styled(Paper)(({ theme }) => ({
  backgroundColor: '#000000',
  padding: theme.spacing(1)
}));
const Title = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  backgroundColor: '#000000',
  color: '#FFFFFF',
  fontSize: 'x-large',
  fontWeight: '700'
}));
const Text = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  backgroundColor: '#000000',
  color: '#FFFFFF',
  '&:hover': {
    textDecorationLine: 'underline',
  },
  cursor: 'pointer',
  width: 'fit-content',
  height: 'fit-content',
  margin: 'auto'
}));

function Click(url) {
  canvasConfetti({
    spread: 360,
    ticks: 50,
    gravity: 0,
    decay: 0.94,
    startVelocity: 30,
    colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8'],
    particleCount: 40,
    scalar: 1.2,
    shapes: ['star']
  });
  setTimeout(() => {
    window.location.href = url;
  }, 500);
}

function App() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container rowSpacing={{ xs: 3, sm: 6, md: 9 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid xs={12}>
          <Spacer></Spacer>
        </Grid>
        <Grid xs={2} sm={4} md={5}>
          <Spacer></Spacer>
        </Grid>
        <Grid xs={8} sm={4} md={2} container rowSpacing={{ xs: 3, sm: 4, md: 2 }}>
          <Grid><img src='0xgalt.png' style={{width: '100%'}} /></Grid>
          <Grid textAlign={'center'} width={'100%'}>
            <Title>0xGalt</Title>
          </Grid>
          <Grid textAlign={'center'} width={'100%'}>
            <Container><Text onClick={() => Click('https://github.com/0xgalt')}>GitHub</Text></Container>
            <Container><Text onClick={() => Click('https://twitter.com/0xGalt')}>Twitter</Text></Container>
            <Container><Text onClick={() => Click('https://t.me/zeroxGalt')}>Telegram</Text></Container>
          </Grid>
        </Grid>
        <Grid xs={2} sm={4} md={5}>
          <Spacer></Spacer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default App;